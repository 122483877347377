import { IVmodelProps } from '@/interfaces/IVmodelProps'
import {
  BButton as BButtonOrig,
  BCard as BCardOrig,
  BRow as BRowOrig,
  BCol as BColOrig,
  BFormInput as BFormInputOrig,
  BModal as BModalOrig,
  BFormGroup as BFormGroupOrig,
  BFormCheckbox as BFormCheckboxOrig,
  BAvatar as BAvatarOrig,
  BOverlay as BOverlayOrig,
  BFormTextarea as BFormTextareaOrig,
  BFormRadioGroup as BFormRadioGroupOrig,
  BTabs as BTabsOrig,
  BTab as BTabOrig,
  BFormFile as BFormFileOrig,
  BCardTitle as BCardTitleOrig,
  BFormRadio as BFormRadioOrig,
  BProgress as BProgressOrig,
  BvEvent,
} from 'bootstrap-vue'
const fkatPickr = require('vue-flatpickr-component')
import { ParsedOptions } from 'flatpickr/dist/types/options'
import { RawLocation } from 'vue-router'
import * as tsx from 'vue-tsx-support'

type Variant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-success'
  | 'outline-danger'
  | 'outline-warning'
  | 'outline-info'
  | 'outline-dark'

type FlatVariant =
  | 'flat-primary'
  | 'flat-secondary'
  | 'flat-success'
  | 'flat-danger'
  | 'flat-warning'
  | 'flat-info'
  | 'flat-dark'

type FlatPickrProps = {
  config?: Partial<ParsedOptions>
  placeholder?: string
  disabled?: boolean
}
export const FlatPickr = tsx
  .ofType<FlatPickrProps & IVmodelProps<string>>()
  .convert(fkatPickr)

type ButtonProps = {
  to?: RawLocation
  href?: string
  target?: '_blank' | '_seld' | '_parent' | '_top'
  onClick?: (e: Event) => void
  variant?: Variant | FlatVariant
  disabled?: boolean
  title?: string
}
export const BButton = tsx.ofType<ButtonProps>().convert(BButtonOrig)

type CardProps = {
  title?: string
  'no-body'?: boolean
}
export const BCard = tsx.ofType<CardProps>().convert(BCardOrig)

type RowProps = {
  cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  'cols-xl'?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  'cols-lg'?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  'cols-md'?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  'cols-sm'?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}
export const BRow = tsx.ofType<RowProps>().convert(BRowOrig)

type ColProps = {
  cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}
export const BCol = tsx.ofType<ColProps>().convert(BColOrig)

export type InputType = 'text' | 'number' | 'email' | 'password'
type InputProps = {
  placeholder?: string
  state?: boolean | null
  disabled?: boolean
  trim?: boolean
  number?: boolean
  maxlength?: number
  type?: InputType
  formatter?: (value: any) => any

  onBlur?: () => void
  onFocus?: () => void
  onClick?: () => void
  onKeydown?: (e: KeyboardEvent) => void
}
export const BInput = tsx
  .ofType<InputProps & IVmodelProps>()
  .convert(BFormInputOrig)

type ModalProps = {
  'cancel-variant'?: Variant
  'ok-only'?: boolean
  'ok-title'?: string
  'cancel-title'?: string
  'ok-disabled'?: boolean
  centered?: boolean
  'button-width'?: string
  'no-close-on-backdrop'?: boolean
  title?: string
  busy?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  'hide-footer'?: boolean
  'hide-header'?: boolean
  onHidden?: () => void
  onOk?: (event: BvEvent) => void
}
export const BModal = tsx.ofType<ModalProps>().convert(BModalOrig)

type FormGroupProps = {
  label?: string
  'invalid-feedback'?: string | null
  state?: boolean | null
  description?: string
}
export const BFormGroup = tsx.ofType<FormGroupProps>().convert(BFormGroupOrig)

type CheckboxProps = {
  checked: boolean
  onInput: (value: boolean) => void
  disabled?: boolean
}
export const BCheckbox = tsx.ofType<CheckboxProps>().convert(BFormCheckboxOrig)

type RadioProps = {
  value?: boolean
  checked?: boolean
  onInput?: (value: boolean) => void
  disabled?: boolean
}
export const BRadio = tsx.ofType<RadioProps>().convert(BFormRadioOrig)

type AvatarProps = {
  src?: string
  text?: string
  square?: boolean
  size?: number | string
}
export const BAvatar = tsx.ofType<AvatarProps>().convert(BAvatarOrig)

type OverlayProps = {
  show?: boolean
  'no-wrap'?: boolean
  rounded?: 'sm' | 'md' | 'lg'
  onClick?: () => void
}
export const BOverlay = tsx.ofType<OverlayProps>().convert(BOverlayOrig)

type TextareaProps = {
  value: string
  onInput: (value: string) => void
  placeholder?: string
  rows?: number
  disabled?: boolean
  maxlength?: number
  trim?: boolean
  state?: boolean | null

  onBlur?: () => void
  onFocus?: () => void
  onClick?: () => void
}
export const BTextarea = tsx.ofType<TextareaProps>().convert(BFormTextareaOrig)

type RadioGroupProps = {
  checked: any
  onchange: (value: any) => void
  options: object[]
  name?: string
}
export const BFormRadioGroup = tsx
  .ofType<RadioGroupProps>()
  .convert(BFormRadioGroupOrig)

type TabProps = {
  title: string
  disabled?: boolean
  'title-link-class'?: string | null
  active?: boolean
}
export const BTab = tsx.ofType<TabProps>().convert(BTabOrig)

type TabsProps = {}
export const BTabs = tsx.ofType<TabsProps>().convert(BTabsOrig)

type FormFileProps = {
  value?: File | null
  onInput?: (file: File) => void
  onChange?: (e: Event) => void
  'browse-text'?: string
  placeholder?: string
  'drop-placeholder'?: string
  accept?: string
  state?: boolean | null
}
export const BFormFile = tsx.ofType<FormFileProps>().convert(BFormFileOrig)

type CardTitleProps = {}
export const BCardTitle = tsx.ofType<CardTitleProps>().convert(BCardTitleOrig)

type ProgressProps = {
  value: number
  max: number
  'show-progress'?: boolean
  'show-value'?: boolean
  striped?: boolean
  animated?: boolean
  height?: string
  variant?: Variant
}
export const BProgress = tsx.ofType<ProgressProps>().convert(BProgressOrig)
